import React, { Suspense } from 'react';
const LoginHandler = React.lazy(() => import('./components/LoginHandler'));
const AdminPage = React.lazy(() => import('./pages/Admin/Index'));
const UserPage = React.lazy(() => import('./pages/UserPage/Index'));
const ConfirmPage = React.lazy(() => import('./pages/ConfirmPage/Index'));
import { Box, createTheme, Theme, ThemeProvider } from '@mui/material';
import Layout from './Layout';
import { StoreContext } from './StateStore';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Fallback from './components/Fallback';
import * as locales from '@mui/material/locale';
import * as xlocales from '@mui/x-data-grid/locales';
import { LanguageMap } from './components/LanguageHandler';

const makeTheme = (mode: 'dark'|'light', lang: string) : Partial<Theme> => {
  return createTheme({
    palette: {
      mode,
    }
  }, locales[LanguageMap[lang]], xlocales[LanguageMap[lang]]);
};

const App: React.FC = () => {
  const [store] = React.useContext(StoreContext);
  return (
    <ThemeProvider theme={makeTheme(store.mode, store.lang.code)}>
      <Box
        sx={{ position: 'fixed', height: '100%', width: '100%', zIndex: -500, backgroundColor: store.mode === 'dark' ? 'rgb(0, 30, 60)' : 'white', top: 0, left: 0 }}
      />
      <Suspense fallback={<Fallback />}>
        <Layout>
          <Suspense fallback={<Fallback />}>
            <LoginHandler>
              {(token) => token ? 
                token.admin ? 
                  <AdminPage />
                  : token.hasLoggedIn ? 
                    <UserPage />
                    : <ConfirmPage />
                : ''
              }
            </LoginHandler>
          </Suspense>
        </Layout>
      </Suspense>
    </ThemeProvider>
  );
};
    
export default App;