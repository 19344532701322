import React from 'react';

const useDimension = (updateOnResize: boolean|'computer' = true): { w: number, h: number } => {
  const [dimension, setDimension] = React.useState({ w: window.innerWidth, h: window.innerHeight });

  const isMobile = window.screenX === 0 && window.devicePixelRatio !== 1;

  React.useEffect(() => {
    if ((isMobile && updateOnResize === 'computer') || !updateOnResize)
      return;
    const listener = () => {
      setDimension({ w: window.innerWidth, h: window.innerHeight });
    };
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);
  return dimension;
};

export default useDimension;