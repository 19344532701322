import React from 'react';
import { WithChildren } from '.';
import { LanguageContextType } from './components/LanguageHandler';
import { TokenContext } from './components/LoginHandler';

export type StoreState = {
  token?: TokenContext,
  lang: LanguageContextType,
  unsavedChanges: {
    has: boolean,
    request: () => boolean,
  },
  mode: 'dark'|'light'
}

export type StoreUpdate = {
  token?: TokenContext,
  lang?: LanguageContextType,
  unsavedChanges?: boolean,
  mode?: 'dark'|'light'
}

export enum StoreActionType {
  SET_TOKEN, SET_LANG, SET_UNSAVED_CHANGES, SET_MODE
}

export type StoreAction = {
  type: StoreActionType,
  payload: StoreUpdate
}

const Reducer = (state: StoreState, action: StoreAction): StoreState => {
  switch(action.type) {
  case StoreActionType.SET_MODE:
    return Object.assign({}, state, action.payload);
  case StoreActionType.SET_TOKEN: 
    return Object.assign({}, state, action.payload);
  case StoreActionType.SET_LANG:
    if (action.payload.lang !== state.lang)
      return Object.assign({}, state, action.payload);
    else
      return state;
  case StoreActionType.SET_UNSAVED_CHANGES:
    if (action.payload.unsavedChanges !== undefined && action.payload.unsavedChanges !== state.unsavedChanges.has) {
      if (action.payload.unsavedChanges === true) {
        return Object.assign({}, state, {
          unsavedChanges: {
            has: true,
            request: () => window.confirm(state.lang.getLang('popup.unsavedChanges'))
          }
        });
      } else {
        return Object.assign({}, state, {
          unsavedChanges: {
            windowListener: undefined,
            has: false,
            request: () => true
          }
        });
      }
    } else {
      return state;
    }
  default: 
    return state;
  }
};

const initialState: StoreState = {
  lang: {
    getLang: (key) => {
      return key;
    },
    hasKey: () => false,
    ready: false,
    code: 'en'
  },
  unsavedChanges: {
    has: false,
    request: () => true,
  },
  mode: 'dark'
};

const Store: React.FC<WithChildren> = ({children}) => {
  const [state, dispatch] = React.useReducer(Reducer, initialState);
  React.useEffect(() => {
    if (!state.unsavedChanges.has)
      return;
    const unsavedChanges = (ev: BeforeUnloadEvent, lang: LanguageContextType) => {
      ev.preventDefault();
      ev.returnValue = lang.getLang('popup.unsavedChanges');
      return lang.getLang('popup.unsavedChanges');
    };


    const windowListener = (ev: BeforeUnloadEvent) => {
      return unsavedChanges(ev, state.lang);
    };

    window.addEventListener('beforeunload', windowListener);

    return () => window.removeEventListener('beforeunload', windowListener);
  }, [state.unsavedChanges.has]);

  return (
    <StoreContext.Provider value={[state, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};
  
export const StoreContext = React.createContext<[StoreState, React.Dispatch<StoreAction>]>([initialState, undefined as any]);
export default Store;