import { useSnackbar } from 'notistack';
import React from 'react';
import { StoreContext, StoreActionType } from '../StateStore';
import { axiosContext } from '../util/AxiosController';
import { LanguageFile, LanguageKeys } from '@api/Language';
import { WithChildren } from '..';
import type * as locales from '@mui/material/locale';
import type * as xlocales from '@mui/x-data-grid/locales';
export type { LanguageFile, LanguageKeys };

export type Variables = {
  [key: string]: string
}

export const variableTemplate = /\${([\w\d]+)}/g;

type IntersectingTypes<T, U> = { [K in Extract<keyof T, keyof U>]: T[K] }

export const LanguageMap: Record<string, keyof IntersectingTypes<typeof locales, typeof xlocales>> = {
  'de': 'deDE',
  'en': 'enUS'
};

export const languageContext = React.createContext<LanguageContextType>({
  getLang: (key, _, _default) => {
    return _default ?? key;
  },
  hasKey: () => {
    return false;
  },
  ready: false,
  code: 'en'
});

const LanguageHandler: React.FC<WithChildren> = ({ children }) => {
  const [langObject, setLangObject] = React.useState<undefined|LanguageContextType>(undefined);
  const [language, setLanguage] = React.useState<string>(window.navigator.language.includes('-') 
    ? window.navigator.language.split('-')[0].toLowerCase() 
    : window.navigator.language
  );
  const [, dispatch] = React.useContext(StoreContext);
  const { enqueueSnackbar } = useSnackbar();
  const [axios] = React.useContext(axiosContext);

  React.useEffect(() => {
    setLanguage(window.navigator.language.includes('-') 
      ? window.navigator.language.split('-')[0].toLowerCase() 
      : window.navigator.language
    );
  }, [window.navigator.language]);
  
  React.useEffect(() => {
    const requestSearch = new URLSearchParams({
      lang: language
    });
    const englishSearch = new URLSearchParams({
      lang: 'en'
    });
    axios({
      url: `/api/localization?${requestSearch.toString()}`,
      responseType: 'text',
    }).then((res) => {
      const data = res.data instanceof Object ? res.data : JSON.parse(res.data);
      const obj = {
        getLang: prepareGetLanguage(data),
        hasKey: prepareHasKey(data),
        ready: true,
        code: language
      };
      setLangObject(obj);
      dispatch({
        type: StoreActionType.SET_LANG,
        payload: {
          lang: obj
        }
      });
    }).catch((e) => {
      console.error(`Unable to fetch language ${language}`, e);
      axios({
        url: `/api/localization?${englishSearch.toString()}`,
        responseType: 'text'
      }).then(res => {
        const data = res.data instanceof Object ? res.data : JSON.parse(res.data);
        const obj = {
          getLang: prepareGetLanguage(data),
          hasKey: prepareHasKey(data),
          ready: true,
          code: 'en'
        };
        setLangObject(obj);
        dispatch({
          type: StoreActionType.SET_LANG,
          payload: {
            lang: obj
          }
        });
      }).catch(() => {
        enqueueSnackbar('Unable to request the english language pack!', {
          variant: 'error'
        });
        console.error('Unable to fetch language en');
      });
    });
  }, [language]);   

  return <languageContext.Provider value={langObject ? langObject : {
    getLang: (key) => key,
    hasKey: () => false,
    ready: false,
    code: 'en'
  }}>
    {children}
  </languageContext.Provider>;
};

const prepareGetLanguage = (langObject: undefined|LanguageTypes) => {
  return (key: LanguageKeys, variables:Variables = {}, _default?: string) : string => {
    if (!langObject)
      return _default ?? key;
    const getAtKey = (key:LanguageKeys): string => {
      const subs = key.split('.');
      let cur: Record<string, any>|string = langObject;
      for (const sub of subs) {
        if (typeof cur === 'string')
          throw new Error(`${langObject}\nInvalid path ${key}`);
        if (!cur)
          return key;
        cur = cur[sub];
      }
      if (typeof cur === 'object')
        console.trace('Used object as path');
      return typeof cur === 'object' ? key : cur;
    };

    const item = getAtKey(key) ?? _default ?? key;
    return variableReplacement(item, variables);
  };
};

const prepareHasKey = (langObject: undefined|LanguageTypes) => {
  return (key?: string) => {
    if (!langObject || !key)
      return false;
    const split = key.split('.');
    let cur: LanguageTypes|string = langObject;

    for (const item of split) {
      if (typeof cur === 'string')
        return false;
      if (!Object.prototype.hasOwnProperty.call(cur, item))
        return false;
      cur = cur[item];
    }

    return true;
  };
};

const variableReplacement = (str: string, variables: Variables) => {
  if (!str)
    return str;
  const vars = str.matchAll(variableTemplate);
  for (const match of vars) {
    if (Object.prototype.hasOwnProperty.call(variables, match[1])) {
      str = str.replace(match[0], variables[match[1]]);
    }
  }

  return str;
};

export default LanguageHandler;
export type LanguageContextType = {
  getLang: getLang,
  hasKey: (key: string) => boolean,
  ready: boolean,
  code: string
}
export type getLang = (key: LanguageKeys, varaibles?: Variables, _default?: string) => string
export type LanguageTypes = {
  [key: string]: string
}
