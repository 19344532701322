import { Logout } from '@mui/icons-material';
import { Box, Fab, styled, Switch, Theme, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { StoreActionType, StoreContext } from './StateStore';
import { axiosContext } from './util/AxiosController';
import { languageContext } from './components/LanguageHandler';
import './css/modalWindow.css';
import useDimension from './hooks/useDimension';
import { WithChildren } from '.';
const MarkdownModal = React.lazy(() => import('./components/MarkdownModal'));

type SpecialSite = undefined|'about'|'legal'|'third_party_licences';

export enum PopUpType {
  INFO, ERROR, WARNING, SUCCSESS
}

export type PopUpUpdate = {
  type: PopUpType,
  content: {
    title: string,
    msg: string
  }
}

export type PopUp = PopUpUpdate & {
  timer: {
    showTime: number,
    start: number
  }
}

const styles = (theme: Theme) => ({
  mdPages: {
    '& *' : {
      color: theme.palette.mode === 'light' ? 'black' : 'white'
    }
  }
});

const Layout: React.FC<WithChildren<{
  classes: Record<string, string>
}>> = ({ children, classes }) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [axios, handleError] = React.useContext(axiosContext);
  const [data, setData] = React.useState<undefined|string>(undefined);
  const { token } = store;
  const lang = React.useContext(languageContext);
  const [modalOpen, setModalOpen] = React.useState(false);

  const dimension = useDimension('computer');

  const handleClick = async (type: SpecialSite) => {
    if (type === undefined) {
      setData('');
      return;
    }
    
    await axios({
      url: type + '.md',
      responseType: 'text'
    }).then((res) => {
      setData(res.data);
      setModalOpen(true);
    }).catch(handleError);
  };

  return <div>
    <Box sx={{
      position: 'fixed',
      display: 'flex',
      width: 'calc(100% - 32px)', // Remove margin to display properly
      left: 0,
      bottom: 0,
      marginLeft: '16px',
      marginRight: '16px',
      alignItems: 'center',
      zIndex: 100
    }}>
      {data && <MarkdownModal data={data} onClose={() => setModalOpen(false)} open={modalOpen} class={classes.mdPages} />}
      <Box sx={{ mb: 1 }}>
        <LightDarkSwitch checked={store.mode === 'dark'} onClick={() => {
          const mode = store.mode === 'light' ? 'dark' : 'light';
          dispatch({
            type: StoreActionType.SET_MODE,
            payload: {
              mode: mode
            }
          });
        }} />
      </Box>
      {token && <Fab variant='extended' size='medium' sx={{
        p: 1,
        mb: 1
      }} onClick={() => token.logout(token.token)} color='primary'>
        <Logout sx={{ mr: dimension.w > 1000 ? 2 : 0 }} />
        {dimension.w > 1000 && lang.getLang('footer.logout')}
      </Fab>}
      <Box display='flex' flexGrow={1} flexDirection='row-reverse' gap={2} sx={{ alignItems: 'center', ml: 2 }}>
        <Typography color="text.primary" component='p' variant='caption'>
          &copy; 2021-2022 Leonard Schütt
        </Typography>
        <Typography color="text.primary" href='#' style={{
          cursor: 'pointer'
        }} component="a" variant='caption' onClick={() => handleClick('third_party_licences')}>
          {lang.getLang('footer.thirdPartyLicences')}
        </Typography>
        <Typography color="text.primary" href='#' style={{
          cursor: 'pointer'
        }} component="a" variant='caption' onClick={() => handleClick('legal')}>
          {lang.getLang('footer.legalNotice')}
        </Typography>
      </Box>
    </Box>
    {children}
  </div>;
};

const LightDarkSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export default withStyles(styles)(Layout);