import axios, { AxiosError, AxiosInstance } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { WithChildren } from '..';
import { StoreContext } from '../StateStore';

type ErrorHandler = (er: AxiosError) => void;

export const axiosContext = React.createContext<[AxiosInstance, ErrorHandler, boolean]>([axios.create(), () => {
  console.log('Illeagal State');
}, false]);

const AxiosController: React.FC<WithChildren> = ({ children }) => {
  const [store] = React.useContext(StoreContext);
  const { enqueueSnackbar } = useSnackbar();
  const { lang, token } = store;
  const errorHandler = (er: AxiosError) => {
    if (import.meta.env.DEV) {
      console.error(er);
    }
    if (er.response) {
      if (er.response.status === 429) {
        const date = new Date(parseInt(er.response.headers.get('X-RateLimit-Reset') as string));
        enqueueSnackbar(lang.getLang('errors.general.rateLimitInfo', {
          time: date.getMinutes() > 0 ? `${date.getMinutes()} ${lang.getLang('main.minutes')}` : `${date.getSeconds()} ${lang.getLang('main.seconds')}` 
        }), {
          variant: 'error'
        });
      } else if (er.response.data) {
        if (er.response.config.method?.toLowerCase() === 'get' && typeof er.response.data === 'string' && !lang.hasKey(er.response.data))
          enqueueSnackbar(lang.getLang('errors.general.withCode', { code: er.response.status.toString() }), {
            variant: 'error'
          });
        else
          enqueueSnackbar(lang.getLang(typeof er.response.data === 'object' ? er.response.data.error : er.response.data), {
            variant: 'error'
          });
      } else {
        enqueueSnackbar(lang.getLang('errors.general.noMoreInfo'), {
          variant: 'error'
        });
      }
    } else {
      enqueueSnackbar(lang.getLang('errors.general.noMoreInfo'), {
        variant: 'error'
      });
    }
  };
  
  return <axiosContext.Provider value={[axios.create({
    timeout: 5000,
    baseURL: window.location.origin + import.meta.env.BASE_URL,
    timeoutErrorMessage: 'timeout',
    responseType: 'text',
    headers: {
      ...(token ? {
        'Authorization': `Token ${token.token}`
      }: {})
    }
  }), errorHandler, !!token]}>
    {children}
  </axiosContext.Provider>;
};

export default AxiosController;