import { CloseOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import '@fontsource/roboto';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Store from './StateStore';
import AxiosController from './util/AxiosController';
import LanguageHandler from './components/LanguageHandler';

export type WithChildren<T = any> = T & {
  children?: React.ReactNode | React.ReactNode[]
}

const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey | undefined) => () => { 
  if (notistackRef.current)
    notistackRef.current.closeSnackbar(key);
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <SnackbarProvider
    ref={notistackRef}
    action={(key) => (
      <IconButton onClick={onClickDismiss(key)} color="inherit">
        <CloseOutlined />
      </IconButton>
    )}
    disableWindowBlurListener
  >
    <Store>
      <AxiosController>
        <LanguageHandler>
          <App />
        </LanguageHandler>
      </AxiosController>
    </Store>
  </SnackbarProvider>
);